import * as React from 'react'

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import { Helmet } from 'react-helmet'

import { ColorModeContext } from 'shared/contexts'

import { COLOR_MODE_KEY, INITIAL_COLOR_MODE_CSS_PROP } from './constants'
import { CssBaseline } from './css_baseline'

import type { PaletteMode } from '@mui/material'
import type { DynamicColors, ThemeColors, Themes } from 'shared/contexts'

type WrapperProps = {
  children: React.ReactNode
  dynamicColors: DynamicColors
  themeColors: ThemeColors
  themes: Themes
}

export const Wrapper = ({
  children,
  dynamicColors,
  themeColors,
  themes,
}: WrapperProps) => {
  const [colorModeState, setColorModeState] = React.useState<PaletteMode>()

  React.useEffect(() => {
    const root = window.document.documentElement
    const initialColorMode = root.style.getPropertyValue(INITIAL_COLOR_MODE_CSS_PROP)

    if (initialColorMode) {
      setColorModeState(initialColorMode as PaletteMode)
    }
  }, [])

  const contextValue = React.useMemo(() => {
    const setColorMode = (newMode: PaletteMode) => {
      localStorage.setItem(COLOR_MODE_KEY, newMode)

      const root = window.document.documentElement

      Object.entries(dynamicColors).forEach(([name, colorByTheme]) => {
        root.style.setProperty(`--color-${name}`, colorByTheme[newMode])
      })

      setColorModeState(newMode)
    }

    return {
      setColorMode,
      colorMode: colorModeState,
    }
  }, [colorModeState, dynamicColors])

  const contextTheme = React.useMemo(() => {
    const themeOptions = themes[colorModeState || 'light']

    return responsiveFontSizes(createTheme(themeOptions))
  }, [colorModeState, themes])

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name='theme-color'
          media='(prefers-color-scheme: light)'
          content={themeColors.light}
        />
        <meta
          name='theme-color'
          media='(prefers-color-scheme: dark)'
          content={themeColors.dark}
        />
      </Helmet>
      <CssBaseline theme={contextTheme} />
      <ColorModeContext.Provider value={contextValue}>
        <ThemeProvider theme={contextTheme}>
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </React.Fragment>
  )
}
