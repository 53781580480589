exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cartas-buque-insignia-tsx": () => import("./../../../src/pages/cartas/buque-insignia.tsx" /* webpackChunkName: "component---src-pages-cartas-buque-insignia-tsx" */),
  "component---src-pages-cartas-esmeralda-tsx": () => import("./../../../src/pages/cartas/esmeralda.tsx" /* webpackChunkName: "component---src-pages-cartas-esmeralda-tsx" */),
  "component---src-pages-convenios-tsx": () => import("./../../../src/pages/convenios.tsx" /* webpackChunkName: "component---src-pages-convenios-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-empresas-tsx": () => import("./../../../src/pages/empresas.tsx" /* webpackChunkName: "component---src-pages-empresas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-museo-tsx": () => import("./../../../src/pages/museo.tsx" /* webpackChunkName: "component---src-pages-museo-tsx" */),
  "component---src-pages-reservar-tsx": () => import("./../../../src/pages/reservar.tsx" /* webpackChunkName: "component---src-pages-reservar-tsx" */)
}

